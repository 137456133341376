export const conversation = (state) => state.app.conversation;
export const roomConversation = (state) => state.app.roomConversation;
export const currentUser = (state) => state.app.currentUser;
export const users = (state) => state.app.users;
export const contacts = (state) => state.app.contacts;
export const rooms = (state) => state.app.rooms;
export const selectedUser = (state) => state.app.selectedUser;
export const selectedRoom = (state) => state.app.selectedRoom;
export const authUser = (state) => state.app.authUser;
export const loadUser = (state) => state.app.loadUser;
export const message = (state) => state.app.message;
export const loading = (state) => state.app.loading;
export const error = (state) => state.app.error;
